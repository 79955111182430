var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { createStandardTools, isToolEnabled } from "@dc-extension-rich-text/common";
import { createMarkdownTools } from "@dc-extension-rich-text/language-markdown";
import { createDynamicContentTools } from "@dc-extension-rich-text/prosemirror-dynamic-content";
import DcContentLinkBlock from "./blocks/DcContentLinkBlock";
import DcImageLinkBlock from "./blocks/DcImageLinkBlock";
import MarkdownBlock from "./blocks/MarkdownBlock";
import { createSchema } from "./schema/createSchema";
// tslint:disable-next-line
var Node = require("prosemirror-model").Node;
var JSONLanguage = /** @class */ (function () {
    function JSONLanguage(options, blockTypes) {
        if (options === void 0) { options = {}; }
        if (blockTypes === void 0) { blockTypes = [
            new MarkdownBlock(options),
            new DcImageLinkBlock(),
            new DcContentLinkBlock()
        ]; }
        this.blockTypes = blockTypes;
        this.name = "json";
        this.label = "JSON";
        var isInlineStylesEnabled = isToolEnabled("inline_styles", options);
        var schema = createSchema(options, isInlineStylesEnabled);
        var tools = __spreadArrays(createStandardTools(schema, options), createDynamicContentTools(schema, options), createMarkdownTools(schema, options));
        this.schema = schema;
        this.tools = tools;
    }
    JSONLanguage.prototype.serialize = function (doc) {
        var _this = this;
        if (!doc) {
            return [];
        }
        doc = doc.toJSON();
        var fragments = [];
        if (!doc.content || doc.content.length === 0) {
            return fragments;
        }
        var nodesByConverter = groupBy(doc.content, function (node) {
            for (var _i = 0, _a = _this.blockTypes; _i < _a.length; _i++) {
                var blockType = _a[_i];
                if (blockType.canSerialize(_this.schema, node)) {
                    return blockType;
                }
            }
        });
        var blocks = [];
        for (var _i = 0, nodesByConverter_1 = nodesByConverter; _i < nodesByConverter_1.length; _i++) {
            var group = nodesByConverter_1[_i];
            var groupBlocks = group.group.serialize(this.schema, group.items);
            blocks = blocks.concat.apply(blocks, groupBlocks);
        }
        return blocks;
    };
    JSONLanguage.prototype.parse = function (blocks) {
        var _this = this;
        if (!blocks || blocks.length === 0) {
            return Node.fromJSON(this.schema, {
                type: "doc",
                content: [
                    {
                        type: "paragraph"
                    }
                ]
            });
        }
        var result = {
            type: "doc",
            content: []
        };
        var blocksGroupedByConverter = groupBy(blocks, function (block) {
            for (var _i = 0, _a = _this.blockTypes; _i < _a.length; _i++) {
                var blockType = _a[_i];
                if (blockType.canParse(_this.schema, block)) {
                    return blockType;
                }
            }
        });
        for (var _i = 0, blocksGroupedByConverter_1 = blocksGroupedByConverter; _i < blocksGroupedByConverter_1.length; _i++) {
            var group = blocksGroupedByConverter_1[_i];
            var parsedNodes = group.group.parse(this.schema, group.items);
            result.content = result.content.concat.apply(result.content, parsedNodes);
        }
        return Node.fromJSON(this.schema, result);
    };
    return JSONLanguage;
}());
export default JSONLanguage;
function groupBy(items, groupFn) {
    var result = [];
    var currentGroup = null;
    var currentGroupItems = [];
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var item = items_1[_i];
        var itemGroup = groupFn(item);
        if (itemGroup === undefined) {
            continue;
        }
        if (currentGroup === null) {
            currentGroup = itemGroup;
            currentGroupItems = [item];
        }
        else if (currentGroup === itemGroup) {
            currentGroupItems.push(item);
        }
        else {
            if (currentGroupItems.length > 0) {
                result.push({
                    group: currentGroup,
                    items: currentGroupItems
                });
            }
            currentGroup = itemGroup;
            currentGroupItems = [item];
        }
    }
    if (currentGroupItems.length > 0 && currentGroup !== null) {
        result.push({
            group: currentGroup,
            items: currentGroupItems
        });
    }
    return result;
}
