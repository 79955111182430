import MarkdownLanguage from "./MarkdownLanguage";
import MarkdownLanguageConfiguration from "./MarkdownLanguageConfiguration";
export default function (options) {
    return {
        language: new MarkdownLanguage(options),
        conf: MarkdownLanguageConfiguration
    };
}
export * from "./schema/createSchema";
export { createMarkdownTools } from "./MarkdownLanguage";
