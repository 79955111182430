var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { isToolEnabled, inBlock } from '@dc-extension-rich-text/common';
// tslint:disable-next-line
import AddCircleIcon from '@material-ui/icons/AddCircle';
// tslint:disable-next-line
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
export function dcImageLink(schema, dialog) {
    var _this = this;
    var node = schema.nodes['dc-image-link'];
    return {
        name: 'dc-image-link',
        label: 'Insert Image',
        displayIcon: React.createElement(ImageSearchIcon, null),
        isEnabled: function (state) { return !inBlock(state); },
        apply: function (state, dispatch, view) { return __awaiter(_this, void 0, void 0, function () {
            var value, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!dialog) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, dialog()];
                    case 2:
                        value = _a.sent();
                        view.dispatch(view.state.tr.replaceSelectionWith(node.createAndFill({
                            value: value
                        })));
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); }
    };
}
export function dcContentLink(schema, contentTypes, dialog) {
    var _this = this;
    var node = schema.nodes['dc-content-link'];
    return {
        name: 'dc-content-link',
        label: 'Insert Content',
        displayIcon: React.createElement(AddCircleIcon, null),
        isEnabled: function (state) { return !inBlock(state); },
        apply: function (state, dispatch, view) { return __awaiter(_this, void 0, void 0, function () {
            var value, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!dialog) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, dialog(contentTypes)];
                    case 2:
                        value = _a.sent();
                        view.dispatch(view.state.tr.replaceSelectionWith(node.createAndFill({
                            value: value
                        })));
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); }
    };
}
export function createDynamicContentTools(schema, options) {
    var tools = [];
    if (isToolEnabled('dc-image-link', options) && schema.nodes['dc-image-link']) {
        tools.push(dcImageLink(schema, options.dialogs ? options.dialogs.getDcImageLink : undefined));
    }
    if (isToolEnabled('dc-content-link', options) &&
        schema.nodes['dc-content-link'] &&
        // must provide a list of content types for this tool to be active
        options.tools && options.tools["dc-content-link"] && options.tools["dc-content-link"].contentTypes && options.tools["dc-content-link"].contentTypes) {
        tools.push(dcContentLink(schema, options.tools["dc-content-link"].contentTypes, options.dialogs ? options.dialogs.getDcContentLink : undefined));
    }
    return tools;
}
