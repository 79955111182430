import { getDefaultSerializerParser } from '@dc-extension-rich-text/language-markdown';
// tslint:disable-next-line
var markdown = require("prosemirror-markdown");
// tslint:disable-next-line
var Node = require("prosemirror-model").Node;
var MarkdownBlock = /** @class */ (function () {
    function MarkdownBlock(options) {
        this.options = options;
    }
    MarkdownBlock.prototype.canSerialize = function (schema, node) {
        this.ensureInit(schema);
        if (!node.type) {
            return false;
        }
        else {
            return this.serializer.nodes[node.type] != null;
        }
    };
    MarkdownBlock.prototype.serialize = function (schema, nodes) {
        this.ensureInit(schema);
        var parsedNodes = Node.fromJSON(schema, {
            type: "doc",
            content: nodes
        });
        return [
            {
                type: "markdown",
                data: this.serializer.serialize(parsedNodes)
            }
        ];
    };
    MarkdownBlock.prototype.canParse = function (schema, block) {
        return block.type === "markdown";
    };
    MarkdownBlock.prototype.parse = function (schema, blocks) {
        var _this = this;
        this.ensureInit(schema);
        var result = [];
        blocks.forEach(function (block) {
            var doc = _this.parser.parse(block.data || '').toJSON();
            result = result.concat.apply(result, doc.content || []);
        });
        return result;
    };
    MarkdownBlock.prototype.ensureInit = function (schema) {
        var _a;
        _a = getDefaultSerializerParser(schema, this.options), this.serializer = _a[0], this.parser = _a[1];
    };
    return MarkdownBlock;
}());
export default MarkdownBlock;
