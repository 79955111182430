import { canInsert } from "@dc-extension-rich-text/common";
import Remove from "@material-ui/icons/Remove";
import React from "react";
export function SoftHyphenTool(schema) {
    return {
        name: "soft_hyphen",
        label: "Insert Soft Hyphen",
        displayIcon: React.createElement(Remove, null),
        apply: function (state, dispatch) {
            dispatch(state.tr.replaceSelectionWith(schema.nodes.soft_hyphen.create()));
        },
        isEnabled: function (state) { return canInsert(state, schema.nodes.soft_hyphen); },
    };
}
