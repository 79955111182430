import JSONLanguage from "./JSONLanguage";
import JSONLanguageConfiguration from "./JSONLanguageConfiguration";
export default function (options) {
    return {
        language: new JSONLanguage(options),
        conf: JSONLanguageConfiguration
    };
}
export * from "./JSONLanguage";
export * from "./JSONLanguageConfiguration";
export * from "./schema/createSchema";
export * from "./blocks/Block";
export * from "./blocks/DcImageLinkBlock";
export * from "./blocks/MarkdownBlock";
