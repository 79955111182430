import { getCustomClass } from "@dc-extension-rich-text/common";
// tslint:disable-next-line
export var paragraph_align = {
    content: "inline*",
    attrs: {
        align: { default: "left" }
    },
    group: "block",
    parseDOM: [
        {
            tag: "p",
            getAttrs: function (dom) {
                return { align: dom.style.textAlign };
            }
        }
    ],
    toDOM: function (node) { return ["p", { style: "text-align: " + (node.attrs.align || "left") }, 0]; }
};
function getHeadingAttrs(level) {
    return function getAttrs(dom) {
        return { align: dom.style.textAlign, level: level };
    };
}
// tslint:disable-next-line
export var heading_align = {
    attrs: {
        level: { default: 1 },
        align: { default: "left" }
    },
    content: "inline*",
    group: "block",
    defining: true,
    parseDOM: [{ tag: "h1", getAttrs: getHeadingAttrs(1) },
        { tag: "h2", getAttrs: getHeadingAttrs(2) },
        { tag: "h3", getAttrs: getHeadingAttrs(3) },
        { tag: "h4", getAttrs: getHeadingAttrs(4) },
        { tag: "h5", getAttrs: getHeadingAttrs(5) },
        { tag: "h6", getAttrs: getHeadingAttrs(6) }],
    toDOM: function (node) { return ["h" + node.attrs.level, { style: "text-align: " + (node.attrs.align || "left") }, 0]; }
};
export var AlignedParagraphToMarkdown = function (options) { return ({
    paragraph: function (state, node) {
        if (node.attrs.align && node.attrs.align !== "left" && node.attrs.align !== "start") {
            // Aligned paragraph
            // Emit paragraph as HTML with the align attribute.
            if (options.useClasses) {
                state.write("<p class=\"" + getCustomClass("amp-align-" + node.attrs.align, options) + "\">");
            }
            else {
                state.write("<p style=\"text-align: " + node.attrs.align + "\">");
            }
            state.renderInline(node);
            state.write("</p>");
            state.closeBlock(node);
        }
        else {
            state.renderInline(node);
            state.closeBlock(node);
        }
    },
}); };
export var AlignedHeaderToMarkdown = function (options) { return ({
    heading: function (state, node) {
        if (node.attrs.align && node.attrs.align !== "left" && node.attrs.align !== "start") {
            // Aligned header
            // Emit header as HTML with the align attribute.
            if (options.useClasses) {
                state.write("<h" + node.attrs.level + " class=\"" + getCustomClass("amp-align-" + node.attrs.align, options) + "\">");
            }
            else {
                state.write("<h" + node.attrs.level + " style=\"text-align: " + node.attrs.align + "\">");
            }
            state.renderInline(node);
            state.write("</h" + node.attrs.level + ">");
            state.closeBlock(node);
        }
        else {
            state.write(state.repeat("#", node.attrs.level) + " ");
            state.renderInline(node);
            state.closeBlock(node);
        }
    },
}); };
