var defaultRowWidth = 8;
function tableRemoveNewlines(input) {
    return input.trimEnd().replace(/\\?\n/g, '<br>').padEnd(defaultRowWidth, ' ');
}
function withTableEscapes(state, action) {
    var start = state.out.length;
    action();
    state.out = state.out.substr(0, start) + tableRemoveNewlines(state.out.substr(start));
    state.closed = false;
}
export var TableToMarkdown = {
    table: function (state, node) {
        var rows = [];
        for (var i = 0; i < node.childCount; i++) {
            var child = node.maybeChild(i);
            if (child != null && child.type.name === 'table_row') {
                rows.push(child);
            }
        }
        if (rows.length === 0) {
            return;
        }
        // First row is the header row. Get column count, and draw it.
        state.render(rows[0]);
        var columnCount = rows[0].childCount; // Assumes first row is header.
        // Draw divider using the number of columns.
        state.write('\n');
        for (var i = 0; i < columnCount; i++) {
            state.write('|----------');
        }
        state.write('|');
        // Second row onwards are regular rows.
        for (var i = 1; i < rows.length; i++) {
            state.write('\n');
            state.render(rows[i]);
        }
        state.closeBlock(node);
    },
    table_row: function (state, node) {
        state.write('| ');
        var _loop_1 = function (i) {
            var child = node.maybeChild(i);
            if (child != null) {
                if (i > 0) {
                    state.write(' | ');
                }
                withTableEscapes(state, function () {
                    state.renderInline(child);
                });
            }
        };
        for (var i = 0; i < node.childCount; i++) {
            _loop_1(i);
        }
        state.write(' |');
    },
    table_cell: function (state, node) {
        state.renderInline(node);
    },
    table_header: function (state, node) {
        state.renderInline(node);
    },
};
