export function isToolEnabled(toolName, options) {
    if (options.tools &&
        options.tools.blacklist &&
        options.tools.blacklist.indexOf(toolName) !== -1) {
        return false;
    }
    if (options.tools &&
        options.tools.whitelist &&
        options.tools.whitelist.indexOf(toolName) === -1) {
        return false;
    }
    return true;
}
var classNameRegex = /^-?[_a-zA-Z]+[_a-zA-Z0-9-]*$/;
export function getCustomClass(defaultName, options) {
    if (options && options.classOverride) {
        var name_1 = options.classOverride[defaultName];
        return (name_1 && classNameRegex.test(name_1)) ? name_1 : defaultName;
    }
    return defaultName;
}
export function getDefaultClass(customName, options) {
    if (options && options.classOverride) {
        var classes_1 = options.classOverride;
        var keys = Object.keys(classes_1);
        return keys.find(function (key) { return classes_1[key] === customName; }) || customName;
    }
    return customName;
}
