import React from 'react';
import ReactDOM from 'react-dom';
import { ChooserActions, ContentItem as ContentItemIcon, DeleteIcon, getContentTypeCard, getContentTypeIcon, StyledFab, Visualization, withTheme } from 'unofficial-dynamic-content-ui';
import InlineChooser from '../InlineChooser/InlineChooser';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
var styles = {
    root: {
        '&$broken, &$invalid': {
            background: '#e5e5e5'
        },
        boxShadow: "0 1px 5px 0 rgba(23,32,44,.2), 0 2px 2px 0 rgba(23,32,44,.1), 0 3px 1px -2px rgba(23,32,44,.1)"
    },
    invalid: {},
    content: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%"
    },
    statusIcons: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
    }
};
var ViewComponent = withStyles(styles)(function (props) {
    var _a;
    var node = props.node, onDelete = props.onDelete, classes = props.classes, options = props.options;
    var value = node.attrs.value;
    var hasValidValue = value && value.id && value.contentType;
    var contentLinkOptions = options.tools && options.tools["dc-content-link"] ? options.tools["dc-content-link"] : undefined;
    var customIcon = contentLinkOptions && contentLinkOptions.contentTypeSettings && hasValidValue ? getContentTypeIcon(contentLinkOptions.contentTypeSettings, value.contentType) : undefined;
    var cardTemplateUrl = contentLinkOptions && contentLinkOptions.contentTypeSettings && hasValidValue ? getContentTypeCard(contentLinkOptions.contentTypeSettings, value.contentType) : undefined;
    var aspectRatio = contentLinkOptions && contentLinkOptions.contentTypeSettings && contentLinkOptions.contentTypeSettings.aspectRatios && hasValidValue ? getContentTypeAspectRatio(contentLinkOptions.contentTypeSettings.aspectRatios, value.contentType) : undefined;
    //Workaround for ts-jest
    var Fab = StyledFab;
    return (React.createElement("div", { className: clsx(classes.root, (_a = {}, _a[classes.invalid] = !hasValidValue, _a)) },
        React.createElement(InlineChooser, { variant: "populated-slot", aspectRatio: aspectRatio || '3:1' },
            React.createElement("div", { className: clsx(classes.content), style: {
                    backgroundImage: "url(" + (customIcon || ContentItemIcon) + ")"
                } }, cardTemplateUrl && options && options.dynamicContent && options.dynamicContent.stagingEnvironment ? (React.createElement(Visualization, { template: cardTemplateUrl, params: {
                    contentItemId: value.id,
                    stagingEnvironment: options.dynamicContent.stagingEnvironment
                } })) : (false)),
            React.createElement(ChooserActions, { variant: "populated-slot" },
                React.createElement(Fab, { variant: "dark", onClick: onDelete }, DeleteIcon)))));
});
export function getContentTypeAspectRatio(aspectRatios, schemaId) {
    for (var _i = 0, _a = Object.keys(aspectRatios); _i < _a.length; _i++) {
        var key = _a[_i];
        if (key === "*" || key === schemaId) {
            return aspectRatios[key];
        }
    }
}
var DcContentLinkView = /** @class */ (function () {
    function DcContentLinkView(node, view, getPos, options) {
        if (options === void 0) { options = {}; }
        this.node = node;
        this.view = view;
        this.getPos = getPos;
        this.options = options;
        this.dom = document.createElement('div');
        this.handleDelete = this.handleDelete.bind(this);
        this.render();
    }
    DcContentLinkView.prototype.handleDelete = function () {
        var start = this.getPos();
        var tr = this.view.state.tr.delete(start, start + this.node.nodeSize);
        this.view.dispatch(tr);
    };
    DcContentLinkView.prototype.render = function () {
        ReactDOM.render(withTheme(React.createElement(ViewComponent, { options: this.options, node: this.node, onDelete: this.handleDelete })), this.dom);
    };
    return DcContentLinkView;
}());
export { DcContentLinkView };
