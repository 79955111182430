// tslint:disable-next-line
export var soft_hyphen = {
    group: "inline",
    inline: true,
    parseDOM: [
        {
            tag: "span[data-shy]",
            getAttrs: function (dom) {
                return {};
            }
        }
    ],
    toDOM: function (node) {
        return [
            "span",
            { "data-shy": true },
            "-"
        ];
    }
};
