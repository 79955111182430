import { canInsert } from "@dc-extension-rich-text/common";
import { BorderClear, FirstPage, LastPage, VerticalAlignBottom, VerticalAlignTop } from "@material-ui/icons";
import TableChart from "@material-ui/icons/TableChart";
import { addColumnAfter, addRowAfter, deleteColumn, deleteRow, deleteTable, } from "prosemirror-tables";
import React from "react";
// tslint:disable-next-line: no-var-requires
var Fragment = require('prosemirror-model').Fragment;
function inTable(state) {
    var path = state.selection.$head.path;
    for (var i = 0; i < path.length; i += 3) {
        if (path[i].type.name === 'table') {
            return true;
        }
    }
    return false;
}
function generateEmptyCell(state, type) {
    return type.create(undefined, Fragment.fromArray([
        state.schema.nodes.paragraph.create()
    ]));
}
function insertTable(state, dispatch) {
    var tr = state.tr.replaceSelectionWith(state.schema.nodes.table.create(undefined, Fragment.fromArray([
        state.schema.nodes.table_row.create(undefined, Fragment.fromArray([
            generateEmptyCell(state, state.schema.nodes.table_header),
            generateEmptyCell(state, state.schema.nodes.table_header),
        ])),
        state.schema.nodes.table_row.create(undefined, Fragment.fromArray([
            generateEmptyCell(state, state.schema.nodes.table_cell),
            generateEmptyCell(state, state.schema.nodes.table_cell),
        ])),
    ])));
    if (dispatch) {
        dispatch(tr);
    }
    return true;
}
export function CreateTableTool(schema) {
    return {
        name: "table_create",
        label: "Create Table",
        displayIcon: React.createElement(TableChart, null),
        apply: insertTable,
        isEnabled: function (state) { return canInsert(state, schema.nodes.table); },
        isVisible: function (state) { return !inTable(state); },
    };
}
export function AddRowTool(schema) {
    return {
        name: "table_row_add",
        label: "Add Row",
        displayIcon: React.createElement(VerticalAlignBottom, null),
        apply: addRowAfter,
        isVisible: function (state) { return inTable(state); },
    };
}
export function AddColumnTool(schema) {
    return {
        name: "table_col_add",
        label: "Add Column",
        displayIcon: React.createElement(LastPage, null),
        apply: addColumnAfter,
        isVisible: function (state) { return inTable(state); },
    };
}
export function DeleteRowTool(schema) {
    return {
        name: "table_row_delete",
        label: "Delete Row",
        displayIcon: React.createElement(VerticalAlignTop, null),
        apply: deleteRow,
        isVisible: function (state) { return inTable(state); },
    };
}
export function DeleteColumnTool(schema) {
    return {
        name: "table_col_delete",
        label: "Delete Column",
        displayIcon: React.createElement(FirstPage, null),
        apply: deleteColumn,
        isVisible: function (state) { return inTable(state); },
    };
}
export function DeleteTableTool(schema) {
    return {
        name: "table_delete",
        label: "Delete Table",
        displayIcon: React.createElement(BorderClear, null),
        apply: deleteTable,
        isVisible: function (state) { return inTable(state); },
    };
}
